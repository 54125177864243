.widgetContentFadeInWrapper {
  opacity: 0;
  animation: fadeIn 1200ms forwards ease;
}

.widgetContentFadeInZoomInWrapper {
  opacity: 0;
  animation: fadeInZoomIn 1000ms forwards ease !important;
}

.widgetContentMoveDownWrapper {
  opacity: 0;
  animation: moveDown 1000ms forwards ease;
  animation-delay: 0.3s;

  &.builderSelectorDemo {
    animation: demoMoveDown 1000ms forwards ease;
    animation-delay: 0;
  }
}

.widgetContentMoveUpWrapper {
  opacity: 0;
  animation: moveUp 1000ms forwards ease;
  animation-delay: 0.3s;
  &.builderSelectorDemo {
    animation: demoMoveUp 1000ms forwards ease;
    animation-delay: 0;
  }
}

.widgetContentSlideLeftWrapper {
  opacity: 0;
  animation: moveLeft 1000ms forwards ease;
  animation-delay: 0.3s;
  &.builderSelectorDemo {
    animation-delay: 0;
    animation: demoMoveLeft 1000ms forwards ease;
  }
}

.widgetContentSlideRightWrapper {
  opacity: 0;
  animation: moveRight 1000ms forwards ease;
  animation-delay: 0.3s;

  &.builderSelectorDemo {
    animation: demoMoveRight 1000ms forwards ease;
    animation-delay: 0;
  }
}

.imageZoomIn {
  transform: scale(1.05);
  transition: transform 2s ease-in-out;
}

.imageZoomOut {
  transform: scale(1);
  transition: transform 2s ease-in-out;
}

.zoomInOnHover {
  transition: transform 0.5s ease-in-out;
  transform: scale(1);
}

.zoomInOnHover:hover {
  transform: scale(1.05);
}

.ambient {
  animation: animationAmbient 30s linear infinite;
}

@keyframes animationAmbient {
  0% {
    transform: rotate(0) translate(16px) rotate(0) scale(1.2);
  }
  100% {
    transform: rotate(360deg) translate(16px) rotate(-360deg) scale(1.2);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInZoomIn {
  0% {
    transform: scale(1.3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes moveUp {
  0% {
    transform: translate(0, -30px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes moveDown {
  0% {
    transform: translate(0, 30px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes moveLeft {
  0% {
    transform: translate(-30px, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes moveRight {
  0% {
    transform: translate(30px, 0);
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

////////////////////////////////////////////
/// /////////////////////////// ////////////
/// DEMO Animation for widget builder UI ///

@keyframes demoMoveUp {
  0% {
    transform: translate(0, -10px);
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes demoMoveDown {
  0% {
    transform: translate(0, 10px);
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes demoMoveLeft {
  0% {
    transform: translate(-10px, 0);
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes demoMoveRight {
  0% {
    transform: translate(10px, 0);
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
